import { Alert, Box } from "@mui/material";

// Reusable Error Alert component to handle both single and array error formats
const ErrorAlert = ({ errors }) => {
  // Handle if errors is an array
  if (Array.isArray(errors) && errors.length > 0) {
    return (
      <Box sx={{ mb: 2, mt: 4, ml: 4, mr: 4 }}>
        {errors.map((error, index) => (
          <Alert key={index} severity="error">
            {error || "An error occurred"}
          </Alert>
        ))}
      </Box>
    );
  }

  // Handle if errors is a single error object
  if (errors) {
    return (
      <Box sx={{ mb: 2, mt: 4 }}>
        <Alert severity="error">{errors}</Alert>
      </Box>
    );
  }

  return null; // If no errors or errors in an unexpected format
};

export default ErrorAlert;
