import { Typography } from "@mui/material";
import "./ListView.scss";

const SecondaryText = ({ fields }) => {
  const firstField = fields[0];
  const lastTwoFields = fields.slice(-2);

  return (
    <>
      {firstField && (
        <Typography variant="caption" color="textSecondary" className="first-field">
          {firstField.label}: {firstField.value}
        </Typography>
      )}

      {lastTwoFields.length === 2 && (
        <Typography variant="caption" className="second-field">
          {`${lastTwoFields[0].label}: ${lastTwoFields[0].value} | ${lastTwoFields[1].label}: ${lastTwoFields[1].value}`}
        </Typography>
      )}
    </>
  );
};

export default SecondaryText;
