import { Box, Typography, Container } from "@mui/material";
import { loginFields } from "../../constants/LoginFields";
import { LOGIN, REGISTRATION } from "../../constants/NavbarConstants";
import { FormProvider, useForm } from "react-hook-form";
import { NavLink, Navigate, useLocation } from "react-router-dom";
import { useLanguageContext } from "../../context/LanguageContext";
import TemplateForm from "../../components/common/forms/TemplateForm";
import OutlinedButton from "../../components/common/layout/OutlinedButton";
import useEmailPasswordAuth from "../../hooks/useEmailPasswordAuth";
import ErrorAlert from "../../components/common/forms/ErrorAlert";
import styles from "./Login.module.scss";
import ProgressSpinner from "../../components/common/forms/ProgressSpinner";
import { useAuth } from "../../context/AuthProvider";

const Login = () => {
  const methods = useForm();
  const { error, loading, getToken } = useEmailPasswordAuth();
  const location = useLocation();
  const { user } = useAuth();

  const { t } = useLanguageContext();
  const translations = t(LOGIN, { returnObjects: true });
  const { helperText } = translations;

  const authenticatedUser = user;

  const lastKnownPage = location.state?.from || "/";

  const onSubmit = async (data) => {
    const { username: email, password } = data;
    try {
      await getToken(email, password);
    } catch (err) {
      console.error("Authentication failed:", err.message);
    }
  };

  if (authenticatedUser && !authenticatedUser.emailVerified) {
    return <Navigate to="/activate-account" />;
  }

  if (authenticatedUser) {
    return <Navigate to={lastKnownPage} />;
  }

  return (
    <>
      {loading && <ProgressSpinner isLoading={loading} />}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Container maxWidth="md">
            <Box className={styles["login-container"]}>
              <Typography variant="h4" mb={4}>
                {helperText.header}
              </Typography>
              <Box ml={2} textAlign="center">
                {error && <ErrorAlert errors={error} />}
              </Box>
              <Box component="div" className={styles["login-form"]}>
                <Typography variant="h5" className={styles.subTitle}>
                  {helperText.subText}
                </Typography>
                <TemplateForm fields={loginFields} translationKey={REGISTRATION} />

                <Box className={styles.linkbox}>
                  <Typography variant="body2" className={styles.subTitle2} component="span">
                    {helperText.createAccount}
                  </Typography>
                  <NavLink component="button" to="/register-user">
                    <Typography variant="body2" className={styles.link} component="span">
                      {helperText.registerLink}
                    </Typography>
                  </NavLink>
                </Box>

                <Typography variant="body2" className={styles.subTitle}>
                  {helperText.loopToy}
                </Typography>

                <Box className={styles.linkbox2}>
                  <OutlinedButton type="submit" customstyle={{ padding: "0.75rem 4rem" }} disabled={loading}>
                    {loading ? `${helperText.loggingIn}` : `${helperText.login}`}
                  </OutlinedButton>
                </Box>
              </Box>
            </Box>
          </Container>
        </form>
      </FormProvider>
    </>
  );
};

export default Login;
