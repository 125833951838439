import { deleteProduct, updateProduct } from "../redux/slices/productSlice";

export const unpublishPopupConfig = (dispatch, setIsUnpublishPopupOpen, id, showSnackbar) => ({
  open: true,
  title: "Do you want to unpublish the product?",
  description: "Want to take a play break? No problem. Simply hide your product now and relist it later. You are always in control.",
  onConfirm: async () => {
    try {
      await dispatch(
        updateProduct({
          productId: id, // Pass the item's UID
          payload: { isPublished: false }, // Send isPublished: false
        })
      );
      showSnackbar("Product unpublished successfully!", "success"); // Show success snackbar
    } catch (error) {
      showSnackbar("Failed to unpublish the product. Please try again.", "error"); // Show error snackbar
    } finally {
      setIsUnpublishPopupOpen(false); // Close the popup
    }
  },
  onCancel: () => setIsUnpublishPopupOpen(false),
  confirmLabel: "Yes",
  cancelLabel: "No",
});

export const deletePopupConfig = (dispatch, setIsDeletePopupOpen, id, showSnackbar) => ({
  open: true,
  title: "Do you want to delete the product listing?",
  description:
    "Do you want to delete the product listing? No problem. It will declutter your page, and the adventures for your toy can continue in your home.",
  onConfirm: async () => {
    try {
      await dispatch(
        deleteProduct({
          productId: id, // Pass the item's UID
        })
      );
      showSnackbar("Product deleted successfully!", "success"); // Show success snackbar
    } catch (error) {
      showSnackbar("Failed to delete the product. Please try again.", "error"); // Show error snackbar
    } finally {
      setIsDeletePopupOpen(false); // Close the popup
    }
  },
  onCancel: () => setIsDeletePopupOpen(false),
  confirmLabel: "Yes",
  cancelLabel: "No",
});
