import styled from "styled-components";
import { Box, Drawer, IconButton, Tabs, Tab } from "@mui/material";

// Wrapper for the entire drawer and content layout
export const DrawerWrapper = styled(Box)`
  display: flex;

  /* Stack elements vertically for smaller devices */
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

// Styled Drawer with custom styles for MuiDrawer-paper
export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 340px; /* Default width for larger devices */
    background-color: #eff2f5;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 140px;

    /* Mobile Devices (up to 600px) */
    @media (max-width: 600px) {
      width: 80%; /* Use percentage for smaller screens */
      height: 100vh;
      margin-top: 140px;
      padding: 10px;
      border-radius: 0;
    }

    /* Medium Devices (600px to 960px) */
    @media (min-width: 600px) and (max-width: 960px) {
      width: 280px; /* Slightly smaller width for tablets */
    }

    /* Small Desktops (960px to 1280px) */
    @media (min-width: 960px) and (max-width: 1280px) {
      width: 340px; /* Moderate width for small desktops */
    }

    /* Larger Desktops (1280px and above) */
    @media (min-width: 1280px) {
      width: 300px; /* Default width for larger desktops */
    }
  }
`;

// Menu Button with consistent styling
export const MenuButton = styled(IconButton)`
  color: #510f5b;
  height: 50px;
  width: 50px;
  margin-bottom: 10px; /* Add spacing for smaller devices */

  @media (min-width: 600px) {
    margin-bottom: 0; /* Remove spacing for larger devices */
  }
`;

// Content Box for the main content area
export const ContentBox = styled(Box)`
  flex-grow: 1;
  padding: 20px;
  transition: margin-left 0.3s ease;

  @media (min-width: 600px) and (max-width: 960px) {
    margin-left: 340px; /* Default for medium screens */
  }

  @media (min-width: 960px) and (max-width: 1280px) {
    margin-left: 300px; /* Default for small desktops */
  }

  @media (min-width: 1280px) {
    margin-left: 0;
  }

  @media (max-width: 600px) {
    margin-left: 0;
    padding: 0;
  }
`;

// Styled Tabs
export const StyledTabs = styled(Tabs)`
  border-right: 1px solid var(--divider-color);

  .MuiTabs-flexContainer {
    gap: 10px; /* Space between tabs */
  }

  .MuiTab-root {
    text-transform: none;
    justify-content: flex-start;
    align-items: baseline;
    padding: 20px 30px;
    border-radius: 10px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
  }

  .Mui-selected {
    background-color: #a3cddb;
    color: #000000;
    font-weight: 600;
    border-radius: 50px;
  }
`;

// Styled Tab
export const StyledTab = styled(Tab)`
  /* Additional styles for Tab, if needed */
`;
