import countryCodes from "../assets/data/countryCodes";

export const editPersonalInfoFields = [
  {
    name: "firstName",
    label: "fields.firstName",
    validation: {
      required: "validation.firstName.required",
    },
    type: "text",
  },
  {
    name: "lastName",
    label: "fields.lastName",
    validation: {
      required: "validation.lastName.required",
    },
    type: "text",
  },
  {
    name: "dob",
    label: "fields.dob",
    validation: {
      required: "validation.dob.required",
    },
    type: "date",
  },
  {
    name: "countryCode",
    label: "fields.countryCode",
    validation: {
      required: "validation.countryCode.required",
    },
    type: "select",
    options: countryCodes,
    getOptionLabel: (option) => `(${option.code}) ${option.name}`,
    getOptionValue: (option) => option.code,
    width: "35%",
  },
  {
    name: "phoneNumber",
    label: "fields.phoneNumber",
    validation: {
      required: "validation.phoneNumber.required",
      minLength: {
        value: 8,
        message: "validation.phoneNumber.minLength",
      },
      maxLength: {
        value: 8,
        message: "validation.phoneNumber.maxLength",
      },
    },
    type: "number",
    width: "82%",
  },
  {
    name: "address",
    label: "fields.address",
    type: "text",
  },
  {
    name: "zip",
    label: "fields.postNumber",
    type: "text",
    width: "30%",
  },
  {
    name: "city",
    label: "fields.city",
    type: "text",
    width: "87%",
  },
  {
    name: "country",
    label: "fields.country",
    type: "select",
    options: countryCodes,
    getOptionLabel: (option) => `${option.name}`,
    getOptionValue: (option) => option.code,
  },
  {
    name: "consentGiven",
    label: "fields.consentGiven",
    validation: {
      required: "validation.consentGiven.required",
    },
    type: "label",
  },
];

export const editPasswordFields = [
  {
    name: "oldPassword",
    label: "fields.oldPassword",
    type: "password",
  },
  {
    name: "createNewPassword",
    label: "fields.createNewPassword",
    helperText: "helperText.password",
    validation: {
      required: "validation.password.required",
      pattern: {
        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        message: "validation.password.pattern",
      },
      minLength: {
        value: 8,
        message: "validation.password.minLength",
      },
    },
    type: "password",
  },
  {
    name: "reEnterPassword",
    label: "fields.reEnterPassword",
    // validation: {
    //   required: "validation.password.required",
    //   pattern: {
    //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    //     message: "validation.password.pattern",
    //   },
    //   minLength: {
    //     value: 8,
    //     message: "validation.password.minLength",
    //   },
    // },
    type: "password",
  },
];
