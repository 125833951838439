import { useState, useCallback } from "react";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const usePhoneNumParser = () => {
  const [parsedPhone, setParsedPhone] = useState(null);
  const [error, setError] = useState(null);

  const parsePhoneNumber = useCallback((phoneNumber) => {
    try {
      const phone = parsePhoneNumberFromString(phoneNumber);
      if (phone) {
        setParsedPhone({
          countryCallingCode: phone.countryCallingCode,
          nationalNumber: phone.nationalNumber,
          country: phone.country, // e.g., 'US' for the United States
        });
        setError(null);
      } else {
        throw new Error("Invalid phone number");
      }
    } catch (err) {
      setParsedPhone(null);
      setError(err.message);
    }
  }, []);

  return {
    parsedPhone,
    error,
    parsePhoneNumber,
  };
};

export default usePhoneNumParser;
