import { Button } from "@mui/material";
import { styled } from "@mui/system";

const ContainedButton = styled(Button)(({ theme, customStyle }) => ({
  backgroundColor: "#ffffff",
  border: "1px solid #510f5b",
  color: "#510f5b",
  padding: "8px 26px",
  borderRadius: "25px",
  "&:hover": {
    backgroundColor: "#510f5b",
    color: "#ffffff",
  },
  "&:disabled": {
    backgroundColor: "#ffffff",
    border: "1px solid #cccccc",
    color: "#cccccc",
    cursor: "not-allowed",
  },
  ...customStyle, // Merge any additional custom styles
}));

export default ContainedButton;
