import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/apiConstants";
import apiClient from "../../utils/axiosInstance";

// Helper Functions
const processSubUsers = (profileData) => {
  if (!profileData || !profileData.subusers) return [];
  return [{ username: `${profileData.firstName} ${profileData.lastName}` }, ...profileData.subusers.map((subuser) => ({ username: subuser.name }))];
};

// Async Thunks
export const fetchUserProfile = createAsyncThunk("user/fetchUserProfile", async (_, { rejectWithValue }) => {
  try {
    const response = await apiClient.get(API_URLS.ME);
    return {
      ...response.data,
      username: `${response.data.firstName} ${response.data.lastName}`,
    };
  } catch (error) {
    console.error("Error in fetchUserProfile:", error);
    return rejectWithValue(error.response ? error.response.data : "Failed to fetch profile");
  }
});

export const updateProfile = createAsyncThunk("user/updateProfile", async (payload, { rejectWithValue }) => {
  try {
    const response = await apiClient.post(`${API_URLS.UPDATE_PROFILE}`, payload);
    return response.data;
  } catch (error) {
    console.error("Error in updateProfile:", error);
    return rejectWithValue(error.response ? error.response.data : "Failed to update profile");
  }
});

// Initial State
const initialState = {
  isAuthenticated: false,
  isActive: false,
  profile: {
    uid: "",
    isActive: false,
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    phoneNumber: "",
    shoppingCart: [],
    username: "",
    deliveryAddress: {},
    subusers: [],
  },
  subUsers: [],
  loading: false,
  error: null,
};

// Slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthenticated(state, action) {
      state.isAuthenticated = true;
      state.isActive = action.payload.emailVerified;
    },
    setAnonymous(state) {
      state.isAuthenticated = false;
      state.isActive = false;
    },
    storeProfile(state, action) {
      state.profile = action.payload;
    },
    clearProfile(state) {
      state.profile = initialState.profile;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch User Profile
      .addCase(fetchUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        const profileData = action.payload;
        state.loading = false;
        state.profile = profileData;
        state.isActive = profileData.isActive;
        state.subUsers = processSubUsers(profileData);
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update Profile
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        const updatedProfile = action.payload;
        state.loading = false;
        state.profile = updatedProfile;
        state.profile.username = `${updatedProfile.firstName} ${updatedProfile.lastName}`;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Selectors
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectUserProfile = (state) => state.auth.profile;
export const selectIsActive = (state) => state.auth.isActive;
export const selectUsersArray = (state) => state.auth.subUsers;

// Actions and Reducer
export const { setIsAuthenticated, setAnonymous, storeProfile, clearProfile } = authSlice.actions;

export default authSlice.reducer;
