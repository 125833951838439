import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Button, Box, Typography } from "@mui/material";
import { ChildrenDetailFields } from "../../constants/ChildrenFields";
import { useLanguageContext } from "../../context/LanguageContext";
import TemplateForm from "../../components/common/forms/TemplateForm";
import ContainedButton from "../../components/common/layout/ContainedButton";
import PageHeader from "./PageHeader";
import classes from "./Registration.module.scss";
import { REGISTRATION } from "../../constants/NavbarConstants";
import { useDispatch, useSelector } from "react-redux";
import { deleteChild, updateChildren } from "../../redux/slices/registrationSlice";
import { fetchUserProfile, selectUserProfile } from "../../redux/slices/authSlice";

const ChildrenStep = ({ onSubmit, pageLabel, subText, renderCustomHeader }) => {
  const { subusers } = useSelector(selectUserProfile);
  const dispatch = useDispatch();
  const { setValue, getValues, watch } = useFormContext();
  const { t } = useLanguageContext();
  const translations = t(REGISTRATION, { returnObjects: true });
  const { buttonLabels, helperText } = translations;

  const [childrenList, setChildrenList] = useState([{ name: "", dob: "", interests: [] }]); // Initialize with one child

  const firstChildData = watch("children[0]") || {};

  const handleAddChild = () => {
    const currentChildren = getValues("children") || []; // Retrieve current form values
    const newChild = { name: "", dob: "", interests: [] }; // Create a new child
    const updatedChildrenList = [...currentChildren, newChild]; // Append the new child to the existing list
    setChildrenList(updatedChildrenList); // Update the state
    setValue("children", updatedChildrenList); // Update form value
  };

  const handleChildDataChange = (index, childData) => {
    const updatedChildren = [...childrenList];
    updatedChildren[index] = childData;
    setChildrenList(updatedChildren);
    setValue("children", updatedChildren); // Update form value
  };

  const handleDeleteChild = async (index) => {
    if (childrenList.length > 1) {
      const childToDelete = childrenList[index];
      const existingChild = subusers.find((subuser) => subuser.name === childToDelete.name);
      const childUid = existingChild?.uid;

      if (childUid) {
        // If child has uid, delete via API and refetch profile
        try {
          await dispatch(deleteChild(childUid));
          await dispatch(fetchUserProfile());
        } catch (error) {
          console.error("Error deleting child:", error);
          return;
        }
      }

      const updatedList = childrenList.filter((_, idx) => idx !== index);
      setChildrenList(updatedList);
      setValue("children", updatedList, { shouldValidate: true });

      if (!childUid) {
        try {
          await dispatch(updateChildren(updatedList));
          await dispatch(fetchUserProfile());
        } catch (error) {
          console.error("Error updating children:", error);
        }
      }
    }
  };

  // Sync children list with form values
  useEffect(() => {
    if (getValues("children")?.length === 0) {
      setChildrenList([{ name: "", dob: "", interests: [] }]);
    } else {
      setChildrenList(getValues("children"));
    }
  }, [getValues]);

  return (
    <>
      <PageHeader pageLabel={pageLabel} subText={subText} />
      {childrenList.map((_, index) => (
        <Box mt={6} mb={3} key={index}>
          <Box className={classes.titleContainer}>
            {renderCustomHeader ? (
              renderCustomHeader(index)
            ) : (
              <Typography variant="h6">{helperText.childDetails.replace("{index}", index + 1)}</Typography>
            )}

            {/* Show delete button only for sections beyond the first */}
            {index > 0 && (
              <Button variant="text" onClick={() => handleDeleteChild(index)} className={classes.addChildrenBtn}>
                {buttonLabels.deleteChild}
              </Button>
            )}
          </Box>
          <Box>
            <TemplateForm
              fields={ChildrenDetailFields(index, translations)}
              onChange={(data) => handleChildDataChange(index, data)}
              translationKey={REGISTRATION}
            />
          </Box>
        </Box>
      ))}
      <Box className={classes.titleContainer}>
        <ContainedButton
          onClick={handleAddChild}
          fullWidth
          disabled={!firstChildData.name || !firstChildData.dob || !firstChildData.interests} // Enable only if first section is filled
        >
          {buttonLabels.addChild}
        </ContainedButton>
      </Box>
    </>
  );
};

export default ChildrenStep;
