export const SHOPPING_CART = "SHOPPING_CART";
export const GLOBAL = "GLOBAL";
export const REGISTRATION = "registration";
export const LISTING = "listing";
export const MODALS = "modals";
export const LANDING_PAGE = "landingPage";
export const CONFIRMATION = "confirmation";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const SETTINGS = "settings";
export const LISTING_TITLE = "listings";
export const PERSONAL_INFO = "personalInfo";
export const CHILDREN = "children";
export const PAYMENT_INFO = "paymentInfo";
export const ACTION = "action";

const ABOUT_US = "ABOUT_US";
const LIST_TOYS = "LIST_TOYS";

const navLinks = [
  {
    name: LIST_TOYS,
    label: "List Toys",
    link: "/list-toys",
  },
  {
    name: ABOUT_US,
    label: "About us",
    link: "/about-us",
  },
];

export default navLinks;
