import ReusableListView from "../corelist/ReusableListView";
import PageSubTitle from "../corelist/PageSubTitle";
import SecondaryText from "../corelist/SecondaryText";
import useFormatUtils from "../../../hooks/useFormatUtils";
import ActionIcons from "../corelist/ActionIcons";
import { useState } from "react";
import { deletePopupConfig } from "../../../constants/ListingActionPopupConfig";
import { useDispatch } from "react-redux";
import { useSnackbar } from "../../../context/SnackbarProvider";
import ActionConfirmPopup from "../corelist/ActionConfirmPopup";
import { useNavigate } from "react-router-dom";

const Unpublished = ({ unpublishedItems, onUpdateListings }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { abbreviateText, formatDate } = useFormatUtils();
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const deleteConfirmConfig =
    isDeletePopupOpen && selectedItem
      ? deletePopupConfig(dispatch, setIsDeletePopupOpen, selectedItem.uid, (message, type) => {
          showSnackbar(message, type);
          onUpdateListings(); // Refresh the listings
        })
      : { open: false };

  const renderAction = (item) => <ActionIcons onDelete={() => handleDelete(item)} onEdit={() => handleEdit(item)} />;

  const renderSecondaryText = (item) => (
    <SecondaryText
      fields={[
        { label: "Best memory", value: abbreviateText(item.memory, 50) || "NA" },
        { label: "Date", value: item.createdAt ? formatDate(item.createdAt) : "NA" },
        { label: "Price", value: `${item.sellingPrice} NOK` || "NA" },
      ]}
    />
  );

  const handleDelete = (item) => {
    setSelectedItem(item);
    setIsDeletePopupOpen(true);
  };

  const handleEdit = (item) => {
    navigate(`/update-toy/${item.uid}`);
  };

  const loadMoreItems = () => {};

  return (
    <>
      <ActionConfirmPopup config={deleteConfirmConfig} />
      <PageSubTitle pageSubTitle={"unpublished"} />
      <ReusableListView
        items={unpublishedItems}
        loadMoreItems={loadMoreItems}
        renderAction={renderAction}
        renderSecondaryText={renderSecondaryText}
      />
    </>
  );
};

export default Unpublished;
