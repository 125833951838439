import { useRef } from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";
import "./EditInfo.scss";

const ChildAvatar = ({ uid, name, photo, onPhotoUpload }) => {
  const fileInputRef = useRef(null);

  // Function to get initials from name
  const getInitials = (fullName) => {
    if (!fullName) return "";
    const parts = fullName.trim().split(" ");
    const initials = parts.map((part) => part[0]?.toUpperCase());
    return initials.join("").slice(0, 2); // Return only the first two initials
  };

  const initials = getInitials(name);

  const handlePhotoClick = () => {
    if (photo) {
      // Remove photo and reset avatar to initials
      onPhotoUpload(null);
    } else {
      // Trigger hidden file input
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onPhotoUpload(file);
    }
  };

  return (
    <Box className="photo-box prevent-drawer-close">
      <Avatar src={photo || ""} alt={name} className="avatar-box">
        {!photo && initials}
      </Avatar>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        {name}
      </Typography>
      <Button
        variant="text"
        className="add-photo-btn"
        onClick={(e) => {
          e.stopPropagation(); // Prevent event bubbling to parent components
          handlePhotoClick();
        }}
        sx={{
          display: uid ? "inline-block" : "none", // Hide button if no UID
        }}
      >
        {photo ? "Remove Photo" : "Add Photo"}
      </Button>
      {/* Hidden file input */}
      <input type="file" accept="image/*" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
    </Box>
  );
};

export default ChildAvatar;
