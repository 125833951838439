import { IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./ListView.scss";

const ActionIcons = ({ onSend, onDelete, onEdit }) => {
  return (
    <>
      {onSend && (
        <IconButton className="icon-btn" onClick={onSend}>
          <SendIcon />
        </IconButton>
      )}

      {onDelete && (
        <IconButton className="icon-btn" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      )}

      {onEdit && (
        <IconButton className="icon-btn" onClick={onEdit}>
          <EditIcon />
        </IconButton>
      )}
    </>
  );
};

export default ActionIcons;
