import "./App.css";
import { RouterProvider } from "react-router-dom";
import router from "./Routes";
// import { createInterceptor } from "./utils/interceptor";

function App() {
  // createInterceptor();

  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
