import { Box, Container, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserProfile, selectUserProfile } from "../../../redux/slices/authSlice";
import { CHILDREN, SETTINGS } from "../../../constants/NavbarConstants";
import ChildrenStep from "../../registration/ChildrenStep";
import ContainedButton from "../../../components/common/layout/ContainedButton";
import PageTitle from "../corelist/PageTitle";
import { useLanguageContext } from "../../../context/LanguageContext";
import ChildAvatar from "./ChildAvatar";
import { addChildPhoto, removeChildPhoto, updateChildren } from "../../../redux/slices/registrationSlice";
import { useSnackbar } from "../../../context/SnackbarProvider";
import ProgressSpinner from "../../../components/common/forms/ProgressSpinner";

const EditChildren = () => {
  const { subusers } = useSelector(selectUserProfile);
  const [avatars, setAvatars] = useState(subusers.map((subuser) => ({ name: subuser.name, photo: null })));
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useLanguageContext();
  const translations = t(SETTINGS, { returnObjects: true });
  const { header, pageSubTitles } = translations;

  // Set default children value based on subusers or empty array
  const defaultChildren = useMemo(() => {
    return subusers.length > 0
      ? subusers.map((subuser) => ({
          uid: subuser.uid || null,
          name: subuser.name || "",
          dob: subuser.dob || "",
          interests: subuser.interests || [],
        }))
      : [{ uid: null, name: "", dob: "", interests: [] }]; // Always show at least one empty child
  }, [subusers]); // Always show at least one empty child

  const methods = useForm({
    defaultValues: {
      children: defaultChildren,
    },
  });

  const { handleSubmit, reset, control } = methods;
  const dispatch = useDispatch();
  const watchedChildren = useWatch({ control, name: "children" });

  useEffect(() => {
    // Sync form state with Redux state when `subUsers` changes
    reset({
      children: defaultChildren,
    });
    setAvatars(subusers.map((subuser) => ({ name: subuser.name, photo: subuser.photo })));
  }, [subusers, reset, defaultChildren]);

  const handlePhotoUpload = async (index, file) => {
    const updateAvatar = (photo) => {
      setAvatars((prevAvatars) => prevAvatars.map((avatar, idx) => (idx === index ? { ...avatar, photo } : avatar)));
    };

    try {
      if (!file) {
        // Remove photo if file is null
        await dispatch(removeChildPhoto(subusers[index].uid));
        updateAvatar(null);
        await dispatch(fetchUserProfile());
        showSnackbar("Photo removed successfully!", "success");
        return;
      }

      const reader = new FileReader();
      reader.onload = async (e) => {
        const base64Photo = e.target.result;
        updateAvatar(base64Photo); // Optimistic UI update

        const formData = new FormData();
        formData.append("photo", file);

        try {
          await dispatch(addChildPhoto({ childUid: subusers[index].uid, childPhoto: formData }));
          await dispatch(fetchUserProfile());
          showSnackbar("Photo uploaded successfully!", "success");
        } catch (error) {
          updateAvatar(null);
          showSnackbar("Failed to upload photo. Please try again.", "error");
        }
      };

      reader.readAsDataURL(file);
    } catch (error) {
      showSnackbar("An error occurred. Please try again.", "error");
    }
  };

  const renderChildHeader = (index) => {
    const currentChild = watchedChildren[index] || {};
    const currentName = watchedChildren[index]?.name || avatars[index]?.name || "";
    return (
      <ChildAvatar uid={currentChild.uid} name={currentName} photo={avatars[index]?.photo} onPhotoUpload={(file) => handlePhotoUpload(index, file)} />
    );
  };

  const onSubmit = async (data) => {
    console.log("data:", data);

    setIsLoading(true); // Show spinner
    try {
      const childrenUpdateResponse = await dispatch(updateChildren(data.children));
      if (!childrenUpdateResponse.error) {
        await dispatch(fetchUserProfile());
        showSnackbar("Children data updated successfully!");
      } else {
        showSnackbar("Failed to update children. Please try again.", "error");
      }
    } catch (error) {
      showSnackbar("An unexpected error occurred.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className="edit-children-box">
      {isLoading && <ProgressSpinner isLoading={isLoading} />}
      <Container maxWidth="md">
        <PageTitle pageTitle={CHILDREN} />
        <Typography variant="body1">{header.editChildren}</Typography>
      </Container>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ChildrenStep onSubmit={onSubmit} pageLabel={pageSubTitles.children} renderCustomHeader={renderChildHeader} />

          <Box my={2} />
          <Container maxWidth="md">
            <Typography my={5} variant="body1">
              {header.childrenSubText}
            </Typography>
            <Box my={8} className="list-toy-btn">
              <ContainedButton type="submit">Save and update</ContainedButton>
            </Box>
          </Container>
        </form>
      </FormProvider>
    </Box>
  );
};

export default EditChildren;
