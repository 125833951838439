export const ChildrenDetailFields = (index, translations) => [
  {
    name: `children[${index}].name`,
    label: translations.fields.childName.replace("{index}", index + 1),
    type: "text",
  },
  {
    name: `children[${index}].dob`,
    label: translations.fields.childDateOfBirth.replace("{index}", index + 1),
    type: "date",
  },
  {
    name: `children[${index}].interests`,
    label: translations.fields.childInterests.replace("{index}", index + 1),
    type: "multiselect",
    helperText: "helperText.childInterests",
    options: [
      { value: "reader", label: translations.interests.reader },
      { value: "hero", label: translations.interests.hero },
      { value: "scientist", label: translations.interests.scientist },
      { value: "architect", label: translations.interests.architect },
      { value: "sporty", label: translations.interests.sporty },
      { value: "gamer", label: translations.interests.gamer },
      { value: "cook", label: translations.interests.cook },
      { value: "musician", label: translations.interests.musician },
      { value: "fashionista", label: translations.interests.fashionista },
      { value: "artist", label: translations.interests.artist },
      { value: "astronaut", label: translations.interests.astronaut },
      { value: "careGiver", label: translations.interests.careGiver },
      { value: "explorer", label: translations.interests.explorer },
      { value: "engineer", label: translations.interests.engineer },
      { value: "entertainer", label: translations.interests.entertainer },
      { value: "collector", label: translations.interests.collector },
      { value: "cuddler", label: translations.interests.cuddler },
      { value: "dreamer", label: translations.interests.dreamer },
      { value: "builder", label: translations.interests.builder },
      { value: "creative", label: translations.interests.creative },
      { value: "mathematician", label: translations.interests.mathematician },
    ],

    getOptionLabel: (option) => option.label,
    getOptionValue: (option) => option.value,
  },
];
