import { Container, Typography } from "@mui/material";

const PageHeader = ({ pageLabel, subText }) => {
  return (
    <>
      <Container maxWidth="md" mt={2} mb={2}>
        <Typography variant="h6" mt={4} gutterBottom>
          {pageLabel}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {subText}
        </Typography>
      </Container>
    </>
  );
};

export default PageHeader;
