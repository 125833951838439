import { Box, Typography } from "@mui/material";
import { PERSONAL_INFO, SETTINGS } from "../../../constants/NavbarConstants";
import { useLanguageContext } from "../../../context/LanguageContext";
import PageTitle from "../corelist/PageTitle";
import ChangePassword from "./ChangePassword";
import UpdatePersonalInfo from "./UpdatePersonalInfo";

const EditInformation = () => {
  const { t } = useLanguageContext();
  const translations = t(SETTINGS, { returnObjects: true });
  const { header } = translations;

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", alignContent: "flex-end", width: "80%" }}>
        <PageTitle pageTitle={PERSONAL_INFO} />
        <Typography variant="body1">{header.personlInfo}</Typography>
        <Box my={3} />
        <ChangePassword />
        <Box my={3} />
        <UpdatePersonalInfo />
      </Box>
    </>
  );
};

export default EditInformation;
