const countryCodes = [
  {
    code: "+47",
    name: "Norway",
    // currency: { name: "Norwegian Krone", value: "NOK" },
  },
  {
    code: "+93",
    name: "Afghanistan",
    // currency: { name: "Afghan Afghani", value: "AFN" },
  },
  {
    code: "+355",
    name: "Albania",
    // currency: { name: "Albanian Lek", value: "ALL" },
  },
  {
    code: "+213",
    name: "Algeria",
    // currency: { name: "Algerian Dinar", value: "DZD" },
  },
  {
    code: "+44",
    name: "United Kingdom",
    // currency: { name: "British Pound Sterling", value: "GBP" },
  },
  {
    code: "+49",
    name: "Germany",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+91",
    name: "India",
    // currency: { name: "Indian Rupee", value: "INR" },
  },
  {
    code: "+62",
    name: "Indonesia",
    // currency: { name: "Indonesian Rupiah", value: "IDR" },
  },
  {
    code: "+61",
    name: "Australia",
    // currency: { name: "Australian Dollar", value: "AUD" },
  },
  {
    code: "+43",
    name: "Austria",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+32",
    name: "Belgium",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+55",
    name: "Brazil",
    // currency: { name: "Brazilian Real", value: "BRL" },
  },
  {
    code: "+86",
    name: "China",
    // currency: { name: "Chinese Yuan", value: "CNY" },
  },
  {
    code: "+45",
    name: "Denmark",
    // currency: { name: "Danish Krone", value: "DKK" },
  },
  {
    code: "+20",
    name: "Egypt",
    // currency: { name: "Egyptian Pound", value: "EGP" },
  },
  {
    code: "+358",
    name: "Finland",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+33",
    name: "France",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+30",
    name: "Greece",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+354",
    name: "Iceland",
    // currency: { name: "Icelandic Króna", value: "ISK" },
  },
  {
    code: "+353",
    name: "Ireland",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+972",
    name: "Israel",
    // currency: { name: "Israeli New Shekel", value: "ILS" },
  },
  {
    code: "+39",
    name: "Italy",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+81",
    name: "Japan",
    // currency: { name: "Japanese Yen", value: "JPY" },
  },
  {
    code: "+962",
    name: "Jordan",
    // currency: { name: "Jordanian Dinar", value: "JOD" },
  },
  {
    code: "+254",
    name: "Kenya",
    // currency: { name: "Kenyan Shilling", value: "KES" },
  },
  {
    code: "+996",
    name: "Kyrgyzstan",
    // currency: { name: "Kyrgyzstani Som", value: "KGS" },
  },
  {
    code: "+371",
    name: "Latvia",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+961",
    name: "Lebanon",
    // currency: { name: "Lebanese Pound", value: "LBP" },
  },
  {
    code: "+266",
    name: "Lesotho",
    // currency: { name: "Lesotho Loti", value: "LSL" },
  },
  {
    code: "+231",
    name: "Liberia",
    // currency: { name: "Liberian Dollar", value: "LRD" },
  },
  {
    code: "+218",
    name: "Libya",
    // currency: { name: "Libyan Dinar", value: "LYD" },
  },
  {
    code: "+423",
    name: "Liechtenstein",
    // currency: { name: "Swiss Franc", value: "CHF" },
  },
  {
    code: "+370",
    name: "Lithuania",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+352",
    name: "Luxembourg",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+853",
    name: "Macao",
    // currency: { name: "Macanese Pataca", value: "MOP" },
  },
  {
    code: "+389",
    name: "Macedonia",
    // currency: { name: "Macedonian Denar", value: "MKD" },
  },
  {
    code: "+261",
    name: "Madagascar",
    // currency: { name: "Malagasy Ariary", value: "MGA" },
  },
  {
    code: "+265",
    name: "Malawi",
    // currency: { name: "Malawian Kwacha", value: "MWK" },
  },
  {
    code: "+60",
    name: "Malaysia",
    // currency: { name: "Malaysian Ringgit", value: "MYR" },
  },
  {
    code: "+960",
    name: "Maldives",
    // currency: { name: "Maldivian Rufiyaa", value: "MVR" },
  },
  {
    code: "+223",
    name: "Mali",
    // currency: { name: "West African CFA Franc", value: "XOF" },
  },
  {
    code: "+356",
    name: "Malta",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+692",
    name: "Marshall Islands",
    // currency: { name: "United States Dollar", value: "USD" },
  },
  {
    code: "+596",
    name: "Martinique",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+222",
    name: "Mauritania",
    // currency: { name: "Mauritanian Ouguiya", value: "MRU" },
  },
  {
    code: "+230",
    name: "Mauritius",
    // currency: { name: "Mauritian Rupee", value: "MUR" },
  },
  {
    code: "+52",
    name: "Mexico",
    // currency: { name: "Mexican Peso", value: "MXN" },
  },
  {
    code: "+691",
    name: "Micronesia",
    // currency: { name: "United States Dollar", value: "USD" },
  },
  {
    code: "+373",
    name: "Moldova",
    // currency: { name: "Moldovan Leu", value: "MDL" },
  },
  {
    code: "+377",
    name: "Monaco",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+976",
    name: "Mongolia",
    // currency: { name: "Mongolian Tögrög", value: "MNT" },
  },
  {
    code: "+382",
    name: "Montenegro",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+1664",
    name: "Montserrat",
    // currency: { name: "East Caribbean Dollar", value: "XCD" },
  },
  {
    code: "+212",
    name: "Morocco",
    // currency: { name: "Moroccan Dirham", value: "MAD" },
  },
  {
    code: "+258",
    name: "Mozambique",
    // currency: { name: "Mozambican Metical", value: "MZN" },
  },
  {
    code: "+95",
    name: "Myanmar",
    // currency: { name: "Burmese Kyat", value: "MMK" },
  },
  {
    code: "+264",
    name: "Namibia",
    // currency: { name: "Namibian Dollar", value: "NAD" },
  },
  {
    code: "+674",
    name: "Nauru",
    // currency: { name: "Australian Dollar", value: "AUD" },
  },
  {
    code: "+977",
    name: "Nepal",
    // currency: { name: "Nepalese Rupee", value: "NPR" },
  },
  {
    code: "+31",
    name: "Netherlands",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+64",
    name: "New Zealand",
    // currency: { name: "New Zealand Dollar", value: "NZD" },
  },
  {
    code: "+505",
    name: "Nicaragua",
    // currency: { name: "Nicaraguan Córdoba", value: "NIO" },
  },
  {
    code: "+227",
    name: "Niger",
    // currency: { name: "West African CFA Franc", value: "XOF" },
  },
  {
    code: "+234",
    name: "Nigeria",
    // currency: { name: "Nigerian Naira", value: "NGN" },
  },
  {
    code: "+968",
    name: "Oman",
    // currency: { name: "Omani Rial", value: "OMR" },
  },
  {
    code: "+92",
    name: "Pakistan",
    // currency: { name: "Pakistani Rupee", value: "PKR" },
  },
  {
    code: "+680",
    name: "Palau",
    // currency: { name: "United States Dollar", value: "USD" },
  },
  {
    code: "+970",
    name: "Palestine",
    // currency: { name: "Israeli New Shekel", value: "ILS" },
  },
  {
    code: "+507",
    name: "Panama",
    // currency: { name: "Panamanian Balboa", value: "PAB" },
  },
  {
    code: "+675",
    name: "Papua New Guinea",
    // currency: { name: "Papua New Guinean Kina", value: "PGK" },
  },
  {
    code: "+595",
    name: "Paraguay",
    // currency: { name: "Paraguayan Guarani", value: "PYG" },
  },
  {
    code: "+51",
    name: "Peru",
    // currency: { name: "Peruvian Sol", value: "PEN" },
  },
  {
    code: "+63",
    name: "Philippines",
    // currency: { name: "Philippine Peso", value: "PHP" },
  },
  {
    code: "+48",
    name: "Poland",
    // currency: { name: "Polish Zloty", value: "PLN" },
  },
  {
    code: "+351",
    name: "Portugal",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+1787",
    name: "Puerto Rico",
    // currency: { name: "United States Dollar", value: "USD" },
  },
  {
    code: "+974",
    name: "Qatar",
    // currency: { name: "Qatari Rial", value: "QAR" },
  },
  {
    code: "+262",
    name: "Réunion",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+40",
    name: "Romania",
    // currency: { name: "Romanian Leu", value: "RON" },
  },
  {
    code: "+7",
    name: "Russia",
    // currency: { name: "Russian Ruble", value: "RUB" },
  },
  {
    code: "+250",
    name: "Rwanda",
    // currency: { name: "Rwandan Franc", value: "RWF" },
  },
  {
    code: "+966",
    name: "Saudi Arabia",
    // currency: { name: "Saudi Riyal", value: "SAR" },
  },
  {
    code: "+221",
    name: "Senegal",
    // currency: { name: "West African CFA Franc", value: "XOF" },
  },
  {
    code: "+381",
    name: "Serbia",
    // currency: { name: "Serbian Dinar", value: "RSD" },
  },
  {
    code: "+65",
    name: "Singapore",
    // currency: { name: "Singapore Dollar", value: "SGD" },
  },
  {
    code: "+421",
    name: "Slovakia",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+386",
    name: "Slovenia",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+252",
    name: "Somalia",
    // currency: { name: "Somali Shilling", value: "SOS" },
  },
  {
    code: "+27",
    name: "South Africa",
    // currency: { name: "South African Rand", value: "ZAR" },
  },
  {
    code: "+82",
    name: "South Korea",
    // currency: { name: "South Korean Won", value: "KRW" },
  },
  {
    code: "+34",
    name: "Spain",
    // currency: { name: "Euro", value: "EUR" },
  },
  {
    code: "+94",
    name: "Sri Lanka",
    // currency: { name: "Sri Lankan Rupee", value: "LKR" },
  },
  {
    code: "+249",
    name: "Sudan",
    // currency: { name: "Sudanese Pound", value: "SDG" },
  },
  {
    code: "+597",
    name: "Suriname",
    // currency: { name: "Surinamese Dollar", value: "SRD" },
  },
  {
    code: "+268",
    name: "Swaziland",
    // currency: { name: "Swazi Lilangeni", value: "SZL" },
  },
  {
    code: "+41",
    name: "Switzerland",
    // currency: { name: "Swiss Franc", value: "CHF" },
  },
  {
    code: "+46",
    name: "Sweden",
    // currency: { name: "Swedish Krona", value: "SEK" },
  },
  {
    code: "+66",
    name: "Thailand",
    // currency: { name: "Thai Baht", value: "THB" },
  },
  {
    code: "+228",
    name: "Togo",
    // currency: { name: "West African CFA Franc", value: "XOF" },
  },
  {
    code: "+676",
    name: "Tonga",
    // currency: { name: "Tongan Paʻanga", value: "TOP" },
  },
  {
    code: "+216",
    name: "Tunisia",
    // currency: { name: "Tunisian Dinar", value: "TND" },
  },
  {
    code: "+90",
    name: "Turkey",
    // currency: { name: "Turkish Lira", value: "TRY" },
  },
  {
    code: "+993",
    name: "Turkmenistan",
    // currency: { name: "Turkmenistani Manat", value: "TMT" },
  },
  {
    code: "+1",
    name: "United States",
    // currency: { name: "United States Dollar", value: "USD" },
  },
  {
    code: "+998",
    name: "Uzbekistan",
    // currency: { name: "Uzbekistani Som", value: "UZS" },
  },
  {
    code: "+678",
    name: "Vanuatu",
    // currency: { name: "Vanuatu Vatu", value: "VUV" },
  },
  {
    code: "+58",
    name: "Venezuela",
    // currency: { name: "Venezuelan Bolívar", value: "VES" },
  },
  {
    code: "+84",
    name: "Vietnam",
    // currency: { name: "Vietnamese Dong", value: "VND" },
  },
];

// const countryCodes = [
//   {
//     code: "+47",
//     name: "Norway",
//     // currency: { name: "Norwegian Krone", value: "NOK" },
//   },
// ];

export default countryCodes;
