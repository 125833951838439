import React, { useState, useEffect } from 'react';
import { Box, Container } from "@mui/material";
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { useForm, FormProvider } from "react-hook-form";
import { editPasswordFields } from "../../../constants/EditInfoFields";
import { REGISTRATION } from "../../../constants/NavbarConstants";
import TemplateForm from "../../../components/common/forms/TemplateForm";
import PageSubTitle from "../corelist/PageSubTitle";
import ContainedButton from "../../../components/common/layout/ContainedButton";
import "../corelist/ListView.scss";
import { useAuth } from "../../../context/AuthProvider";

const ChangePassword = () => {
  const { user: currentUser } = useAuth();
  const methods = useForm();
  const { setError } = methods;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  const onSubmit = async (data) => {
    setLoading(true);
    setSuccess(null);

    if (!currentUser) {
      setLoading(false);
      return;
    }

    if (data.createNewPassword !== data.reEnterPassword) {
      setError("reEnterPassword", { type: "mismatch" });
      setLoading(false);
      return;
    }

    if (data.oldPassword === data.createNewPassword) {
      setError("createNewPassword", { type: "same" });
      setLoading(false);
      return;
    }

    const oldCredential = EmailAuthProvider.credential(currentUser.email, data.oldPassword);
    const newPassword = data.createNewPassword;

    try {
      await reauthenticateWithCredential(currentUser, oldCredential);
  

      await updatePassword(currentUser, newPassword);

      const credential = EmailAuthProvider.credential(currentUser.email, newPassword);
      await reauthenticateWithCredential(currentUser, credential);

      setSuccess("Password updated successfully!");
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        setError("oldPassword", { type: "invalid" });
      } else {
        // should never happend but still
        setError("__all__" , { type: "error", message: error.message });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageSubTitle pageSubTitle={"password"} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <TemplateForm fields={editPasswordFields} translationKey={REGISTRATION} />
          <Container maxWidth="md">
            <Box my={3} className="list-toy-btn">
              <ContainedButton type="submit" disabled={loading}>
                {loading ? "Updating..." : "Save and update"}
              </ContainedButton>
            </Box>
          </Container>
        </form>
      </FormProvider>
      {success && <p style={{ color: 'green' }}>{success}</p>}
    </>
  );
};

export default ChangePassword;
