import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Button, Alert } from "@mui/material";
import apiClient from "../../../utils/axiosInstance";
import { selectUserProfile } from "../../../redux/slices/authSlice";
import { useAuth } from "../../../context/AuthProvider";
import PageTitle from "../corelist/PageTitle";
import PageSubTitle from "../corelist/PageSubTitle";
import styles from "./StripeConnectAccount.module.scss";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

async function createAccount() {
  let account, error;

  try {
    const response = await apiClient.post(`${BASE_API_URL}/stripe/account`);
    const json = response.data;
    account = json.account;
    error = json.error;
  } catch (err) {
    error = err;
  }

  return { account, error };
}

async function createAccountLink(account) {
  let url, error;

  try {
    const response = await apiClient.post(`${BASE_API_URL}/stripe/account_link`, {
      account,
    });

    const json = response.data;
    url = json.url;
    error = json.error;
  } catch (err) {
    error = err;
  }

  return { url, error };
}

async function getExpressDashboardLink() {
  let url, error;

  try {
    const response = await apiClient.get(`${BASE_API_URL}/stripe/express-dashboard-link`);
    const json = response.data;
    url = json.url;
    error = json.error;
  } catch (err) {
    error = err;
  }

  return { url, error };
}

const getStripeAccountStatus = (userProfile) => {
  if (userProfile?.stripe?.accountStatus === "COMPLETE") {
    return "connected";
  }
  if (userProfile?.stripe?.accountStatus === "RESTRICTED") {
    return "restricted";
  }
  if (userProfile?.stripe?.accountStatus === "ACTIVE") {
    return "docRequired";
  }
  if (userProfile?.stripe?.accountId) {
    return "created";
  }
  return "pending";
};

const WhiteButton = ({ onClick, children }) => (
  <Button
    variant="outlined"
    onClick={onClick}
    sx={{
      color: "#85578C",
      borderColor: "#85578C",
      borderRadius: "100px",
      textTransform: "none",
      gap: "8px",
      "&:hover": {
        borderColor: "#AA89AF",
      },
    }}
  >
    {children}
  </Button>
);

const PurpleButton = ({ onClick, children }) => (
  <Button
    variant="contained"
    onClick={onClick}
    sx={{
      backgroundColor: "#510F5B",
      color: "#fff",
      borderRadius: "100px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#510F5B",
      },
    }}
  >
    {children}
  </Button>
);

const StripeConnectCompleted = ({ onClick, loading }) => (
  <>
    <PageSubTitle pageSubTitle={"connectAccountComplete"} />
    <Box my={3} />
    <WhiteButton onClick={onClick} disabled={loading}>
      {loading ? "Preparing..." : "View account details"}
    </WhiteButton>
  </>
);

const StripeConnectPending = ({ onClick, loading }) => (
  <>
    <PageSubTitle pageSubTitle={"connectAccountPending"} />
    <Box my={3} />
    <PurpleButton onClick={onClick} disabled={loading}>
      {loading ? "Preapring..." : "Get Account"}
    </PurpleButton>
  </>
);

const StripeConnectRestricted = () => (
  <>
    {/* <PageSubTitle pageSubTitle={"connectAccountRestricted"} /> */}
    <Typography variant="body2">Your account is restricted. Please contact support.</Typography>
  </>
);

const StripeConnectAccountReturn = ({ onClick, loading }) => (
  <>
    <PageSubTitle pageSubTitle={"connectAccountIncomplete"} />
    <Box my={3} />
    <PurpleButton onClick={onClick} disabled={loading}>
      {loading ? "Preparing..." : "Complete onboarding"}
    </PurpleButton>
  </>
);

export default function SetupStripeConnectAccount(props) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const userProfile = useSelector(selectUserProfile);

  const { user: currentUser, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    return <div>Not logged in</div>;
  }

  const connectAccountState = getStripeAccountStatus(userProfile);

  const handleCreateAccount = async () => {
    setLoading(true);
    setError(false);

    const { account, error } = await createAccount();
    if (account) {
      const { url, error: linkError } = await createAccountLink(account);
      setLoading(false);

      if (url) {
        window.location.href = url;
      }

      if (linkError) {
        setError(true);
      }
    }

    if (error) {
      setLoading(false);
      setError(true);
    }
  };

  const handleCreateAccountLink = async () => {
    setLoading(true);
    setError(false);

    const { url, error } = await createAccountLink(userProfile.stripe.accountId);
    setLoading(false);

    if (url) {
      window.location.href = url;
    }

    if (error) {
      setError(true);
    }
  };

  const viewExpressDashboard = async () => {
    const { url, error } = await getExpressDashboardLink();
    if (url) {
      window.open(url, "_blank");
    } else {
      console.error("Error creating account link", error);
    }
  };

  return (
    <>
      <PageTitle pageTitle={"paymentInfo"} />
      <Typography variant="body2" gutterBottom>
        Loopyty is partnered with the payment processing service Stripe. Stripe <br />
        has been audited by a PCI-certified auditor and is certified to 
        <a
          className={styles.link}
          href="https://www.visa.com/splisting/searchGrsp.do?companyNameCriteria=stripe"
          target="_blank"
          rel="noopener noreferrer"
        >
          PCI Service
          <br />
          Provider Level 1
        </a>
        . This is the most stringent level of certification available
        <br />
        in the payments industry.
      </Typography>

      {connectAccountState === "connected" && (
        <>
          <Box my={6} />
          <Typography variant="body2" gutterBottom>
            No payout is scheduled, remember to share you listings with friends and family
          </Typography>
        </>
      )}
      <Box my={6} />

      {connectAccountState === "pending" && <StripeConnectPending onClick={handleCreateAccount} loading={isLoading} />}
      {connectAccountState === "created" && <StripeConnectAccountReturn onClick={handleCreateAccountLink} loading={isLoading} />}
      {connectAccountState === "docRequired" && <StripeConnectAccountReturn onClick={handleCreateAccountLink} loading={isLoading} />}
      {connectAccountState === "connected" && <StripeConnectCompleted onClick={viewExpressDashboard} loading={isLoading} />}
      {connectAccountState === "restricted" && <StripeConnectRestricted />}
      {error && <Alert severity="error">Something went wrong!</Alert>}
      {/* {isLoading && <CircularProgress />} */}
    </>
  );
}
