import { combineReducers } from "@reduxjs/toolkit";
import registrationReducer from "./slices/registrationSlice";
import authReducer from "./slices/authSlice";
import cartReducer from "./slices/cartSlice";
import productReducer from "./slices/productSlice";

const rootReducer = combineReducers({
  registration: registrationReducer,
  auth: authReducer,
  cart: cartReducer,
  product: productReducer,
});

export default rootReducer;
