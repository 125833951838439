import { useState } from "react";
import { Grid, TextField, FormHelperText, InputAdornment, IconButton } from "@mui/material";
import { useController } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const TextFieldComponent = ({ name, control, label, type = "text", sx, className, helperText, rules, calculateHelperText, ...rest }) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({ name, control, rules });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  const calculatePriceHelperText = (value) => {
    const basePrice = parseFloat(value) || 0;
    const serviceCharge = basePrice * 0.13;
    const totalPrice = basePrice + serviceCharge;
    return `You will make ${basePrice.toFixed(2)}kr, and the customer will pay ${totalPrice.toFixed(2)}kr (including a 13% service charge).`;
  };

  const dynamicHelperText =
    name === "yourPrice" && value ? calculatePriceHelperText(value) : typeof helperText === "function" ? helperText(value) : helperText;

  return (
    <Grid sx={sx}>
      <TextField
        color="secondary"
        label={label}
        type={type === "password" && !showPassword ? "password" : "text"}
        InputProps={{
          endAdornment: type === "password" && (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility} onMouseDown={(e) => e.preventDefault()} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          onChange,
          onBlur,
          inputRef: ref,
          value: value || "",
          error: !!error,
        }}
        fullWidth
        margin="normal"
        error={!!error} // Set error prop
        className={className}
        {...rest}
      />
      {(error || helperText) && (
        <FormHelperText error={!!error}>
          <span dangerouslySetInnerHTML={{ __html: dynamicHelperText }} />
        </FormHelperText>
      )}
    </Grid>
  );
};

export default TextFieldComponent;
