import ProtectedRoute from "../pages/login/ProtectedRoute";
import EditInformation from "../pages/settings/edit-information/EditInformation";
import EditChildren from "../pages/settings/edit-information/EditChildren";
import AllListings from "../pages/settings/listing/AllListings";
import SetupStripeConnectAccount from "../pages/settings/stripe/StripeConnectAccount";
//import Purchases from "../pages/settings/purchases/Purchases";
//import SoldToys from "../pages/settings/sold/SoldToys";

export const PersonalMenus = [
  {
    name: "listings",
    route: "listings",
    label: "Listings",
    content: (
      <ProtectedRoute>
        <AllListings />
      </ProtectedRoute>
    ),
  },
  // {
  //   name: "purchases",
  //   label: "Purchases",
  //   content: <Purchases />,
  // },
  // {
  //   name: "sold",
  //   label: "Sold and re-homed toys",
  //   content: <SoldToys />,
  // },
  // {
  //   name: "action",
  //   label: "Action!",
  //   content: <p>Action content goes here.</p>,
  // },
  {
    name: "editInformation",
    route: "edit-information",
    label: "Edit information",
    content: (
      <ProtectedRoute>
        <EditInformation />
      </ProtectedRoute>
    ),
  },
  {
    name: "paymentDetails",
    route: "payment-details",
    label: "Payment details",
    content: (
      <ProtectedRoute>
        <SetupStripeConnectAccount />
      </ProtectedRoute>
    ),
  },
  {
    name: "editChildren",
    route: "edit-children",
    label: "Edit children",
    content: (
      <ProtectedRoute>
        <EditChildren />
      </ProtectedRoute>
    ),
  },
];
