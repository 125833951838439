import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { PersonalMenus as tabs } from "../../../constants/PersonalMenus";
import { DrawerWrapper, StyledDrawer, MenuButton, ContentBox } from "./SettingsMenuDrawer.styled";
import { useSettingsDrawerContext } from "../../../context/SettingsDrawerContext";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerHeader from "./DrawerHeader";
import DrawerTabs from "./DrawerTabs";
import ContentDisplay from "./ContentDisplay";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function SettingsMenuDrawer() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { drawerOpen, closeDrawer, toggleDrawer, drawerRef } = useSettingsDrawerContext();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile: up to 600px
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablet: 600px to 960px
  const shouldShowMenuButton = !drawerOpen || isMobile;

  const handleTabChange = (event, newValue) => {
    navigate(`/settings/${tabs[newValue]?.route}`);
    if (isMobile || isTablet) {
      closeDrawer();
    }
  };

  // Determine active tab based on current URL
  const activeTab = tabs.findIndex((tab) => location.pathname.includes(tab.route));

  return (
    <DrawerWrapper>
      {shouldShowMenuButton && (
        <MenuButton edge="start" aria-label="menu" onClick={toggleDrawer}>
          <MenuIcon />
        </MenuButton>
      )}
      <StyledDrawer ref={drawerRef} variant="persistent" anchor="left" open={drawerOpen} onClose={closeDrawer}>
        <DrawerHeader theme={theme} handleCloseDrawer={closeDrawer} />
        <DrawerTabs tabs={tabs} activeTab={activeTab !== -1 ? activeTab : 0} handleTabChange={handleTabChange} />
      </StyledDrawer>
      <ContentBox
        style={{
          marginLeft: drawerOpen && isTablet ? "340px" : "0", // Apply dynamic margin based on conditions
        }}
      >
        <ContentDisplay tabs={tabs} activeTab={activeTab} />
      </ContentBox>
    </DrawerWrapper>
  );
}
