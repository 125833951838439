// axiosInstance.js
import axios from "axios";
import { BASE_URL } from "./apiConstants";
import { auth } from "../config/firebase";

const apiClient = axios.create({
  baseURL: BASE_URL,
});

// Attach ID token to protected requests
apiClient.interceptors.request.use(async (config) => {
  const user = auth.currentUser;
  if (user) {
    // Get the user's ID token
    // will also refresh the token if it has expired
    const token = await user.getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error)
});

apiClient.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.status === 401) {

    if (error.response.data.errorCode === "FirebaseAccessTokenRevokedError") {
      // Token has been revoked on the server, the user must sign in again
      // Sign out the user
      auth.signOut();
    }
  }
  return Promise.reject(error);
});

export default apiClient;
