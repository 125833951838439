import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';

import classes from "./NotFound404.module.scss";
import NotFoundImage from '../../assets/images/404.jpeg';

const NotFound404 = () => {
    return (
        <Container maxWidth="md" mt={2} mb={2}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                mt={2}
                mb={2}
            >
            <Typography
                variant="h1"
                mt={4}
                gutterBottom
                className={classes.heading}
            >
                404 is lost in the greenery!
            </Typography>
            <Typography
                variant="body1"
                gutterBottom
                className={classes.subHeading}
            >
                Oops! It seems 404 has wandered off the beaten path!
            </Typography>
            <Box position="relative" textAlign="right" m={2} className={classes.imageContainer}>
                <img src={NotFoundImage} alt="404" style={{ width: '100%', height: 'auto' }} />
                <Typography variant="caption" display="block" className={classes.imageCredit}>
                    Photo by Jana Shnipelson
                </Typography>
            </Box>
            <Typography variant="body2" paragraph className={classes.descriptionText}>
                Our adventurous friend, 404, hopped out of the car window and is happily <br />munching on grass, herbs, and leaves—his favourite snacks!
            </Typography>
            <Typography variant="body2" paragraph className={classes.descriptionText}>
                Don’t worry, you can meet some new friends or find 404 a little help right here:<br />
                <Link href="/" underline="always" className={classes.button}>
                    SEARCH
                </Link>
            </Typography>
            <Typography variant="body2" paragraph className={classes.descriptionText}>
                We will keep an eye out for 404 while you explore!
            </Typography>
            </Box>
        </Container>
    );
}

export default NotFound404;