import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return null; // Render nothing while loading
  }

  if (!user) {
    return <Navigate to="/login" />; // Redirect to login if not authenticated
  }

  if (!user.emailVerified) {
    return <Navigate to="/activate-account" />; // Redirect to account activation if email not verified
  }

  return children; // Render the children if authenticated
};

export default ProtectedRoute;
