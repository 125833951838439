import { useState } from "react";
import { List, ListItem, ListItemAvatar, ListItemText, Avatar, Button, Typography, Box } from "@mui/material";
import "./ListView.scss";

const ReusableListView = ({ items, loadMoreItems, renderAction, renderSecondaryText }) => {
  const [visibleItems, setVisibleItems] = useState(4);

  const handleLoadMore = () => {
    loadMoreItems();
    setVisibleItems((prev) => prev + 4);
  };

  const getFirstImage = (media) => {
    const firstImage = media.find((item) => item.type === "image");
    return firstImage ? firstImage.value : ""; // Default to empty if no image found
  };

  return (
    <>
      {items.length === 0 ? (
        // Message when no items are available
        <Box className="no-items">
          <Typography variant="h6" color="textSecondary">
            No items available.
          </Typography>
        </Box>
      ) : (
        <>
          <List className="core-list">
            {items.slice(0, visibleItems).map((item, index) => (
              <ListItem key={index} divider className="list-item">
                {/* Image */}
                <ListItemAvatar className="list-item__avatar">
                  <Avatar src={getFirstImage(item.media)} alt={item.title} variant="square" />
                </ListItemAvatar>

                {/* Details */}
                <Box className="list-item__details">
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" color="black">
                        {item.title}
                      </Typography>
                    }
                    secondary={
                      renderSecondaryText ? (
                        renderSecondaryText(item)
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          No additional information provided.
                        </Typography>
                      )
                    }
                  />
                </Box>

                {/* Action Button */}
                <Box className="list-item__action">{renderAction(item)}</Box>
              </ListItem>
            ))}
          </List>

          {/* Load more button */}
          {visibleItems < items.length && (
            <Box className="load-more">
              <Button variant="text" className="add-photo-btn" onClick={handleLoadMore}>
                Load more
              </Button>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default ReusableListView;
