import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LISTING_TITLE } from "../../../constants/NavbarConstants";
import { fetchAllProducts } from "../../../redux/slices/productSlice";
import ContainedButton from "../../../components/common/layout/ContainedButton";
import PageTitle from "../corelist/PageTitle";
import Published from "./Published";
import "../corelist/ListView.scss";
import { useNavigate } from "react-router-dom";
import Unpublished from "./Unpublished";
import ProgressSpinner from "../../../components/common/forms/ProgressSpinner";

const AllListings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [publishedItems, setPublishedItems] = useState([]);
  const [unpublishedItems, setUnpublishedItems] = useState([]);
  const { loading } = useSelector((state) => state.product);

  const fetchListings = useCallback(() => {
    // Fetch published products
    dispatch(fetchAllProducts({ my: true, isPublished: true })).then((res) => {
      setPublishedItems(res.payload?.items || []);
    });

    // Fetch unpublished products
    dispatch(fetchAllProducts({ my: true, isPublished: false })).then((res) => {
      setUnpublishedItems(res.payload?.items || []);
    });
  }, [dispatch]);

  useEffect(() => {
    fetchListings(); // Initial fetch
  }, [fetchListings]);

  const handleListMore = () => {
    navigate("/list-toys");
  };

  return (
    <>
      {loading && <ProgressSpinner isLoading={loading} />}

      <PageTitle pageTitle={LISTING_TITLE} />
      <Box className="list-toy-btn">
        <ContainedButton onClick={handleListMore}>List More toys</ContainedButton>
      </Box>
      <Unpublished unpublishedItems={unpublishedItems} onUpdateListings={fetchListings} />
      <Box my={6} />
      <Published publishedItems={publishedItems} onUpdateListings={fetchListings} />
    </>
  );
};

export default AllListings;
